'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.assets.factory:AssetManager

 # @description

###
angular
.module 'mundoAdmin.assets'
.factory 'AssetManager', [
  'Restangular'
  'TenantManager'
  'AssetTypesManager'
  '_'
  'MundoDefaultManager'
  '$mdDialog'
  'AssetDataManager'
  'AssetStatusManager'
  '$translate'
  (Restangular
    TenantManager
    AssetTypesManager
    _
    MundoDefaultManager
    $mdDialog
    AssetDataManager
    AssetStatusManager
    $translate)->
    AssetManagerBase = new MundoDefaultManager()

    AssetManagerBase.setUrl('assets')
    AssetManagerBase.setAutoTenant()
    AssetManagerBase.setNewObject(['label', 'assetType', 'assetStatus', 'comments'])
    AssetManagerBase.setUpdateObject(['label', 'assetType', 'assetStatus', 'comments'])
    AssetManagerBase.setSearchFields(['label', 'assetType'])
    AssetManagerBase.templateOptions = {
      folder: 'mundo-admin/assets/views/data'
    }
    AssetManagerBase.editPermission = 'manage all MundoMosaAssetBundle:Asset entities'
    AssetManagerBase.dataTemplates = {
      MapMarker: 'mundo-admin/assets/views/data/map-marker.tpl.html'
    }

    AssetManagerBase.getNewForm = ->
      [
        key: 'label'
        type: 'input'
        name: 'label'
        templateOptions:
          label: 'Label'
          placeholder: 'label'
          required: true
      ,
        key: 'assetType'
        type: 'select'
        name: 'assetType'
        templateOptions:
          label: 'Type'
          placeholder: 'type'
          required: true
          labelProp: "label"
          valueProp: "label"
          options: AssetTypesManager.getListCustom()
      ,
        key: 'assetStatus'
        name: 'assetStatus'
        type: 'select'
        templateOptions:
          label: $translate.instant('datatable.label.status')
          placeholder: $translate.instant('datatable.label.status')
          required: true
          labelProp: "label"
          valueProp: "id"
          options: AssetStatusManager.getFullList({'sort' : 'label,ASC'}).$object
      ,
        key: 'comments'
        type: 'textarea'
        name: 'comments'
        templateOptions:
          label: 'Comments'
          placeholder: 'comments'
          required: false
          rows: 5
      ]

    AssetManagerBase.getEditForm = (data)->
      [
        key: 'label'
        name: 'label'
        type: 'input'
        defaultValue: data.label
        templateOptions:
          label: 'Label'
          placeholder: 'label'
          required: true
      ,
        key: 'assetType'
        name: 'assetType'
        type: 'select'
        defaultValue: data.assetType
        templateOptions:
          label: 'Type'
          placeholder: 'type'
          required: true
          labelProp: "label"
          valueProp: "label"
          options: AssetTypesManager.getListCustom()
      ,
        key: 'assetStatus'
        name: 'assetStatus'
        type: 'select'
        defaultValue: if data.assetStatus then data.assetStatus.id else ''
        templateOptions:
          label: $translate.instant('datatable.label.status')
          placeholder: $translate.instant('datatable.label.status')
          required: true
          labelProp: "label"
          valueProp: "id"
          options: AssetStatusManager.getFullList({'sort' : 'label,ASC'}).$object
      ,
        key: 'comments'
        name: 'comments'
        type: 'textarea'
        defaultValue: data.comments
        templateOptions:
          label: 'Comments'
          placeholder: 'comments'
          required: false
          rows: 5
      ]

    AssetManagerBase.getListSchema = ->
      [
        key: 'label'
        title: 'datatable.label.label'
        sort: 'label'
      ,
        key: 'assetType'
        title: 'datatable.label.assetType'
        sort: 'assetType'
      ,
        key: 'assetStatus.translatedLabel'
        title: 'datatable.label.status'
        sort: 'assetStatus.label'
        searchable: true
      ,
        key: 'assetData'
        title: 'datatable.label.assetData'
        template: 'mundo-admin/assets/views/asset-data.tpl.html'
        modalTemplate: 'mundo-admin/assets/views/asset-data-modal.tpl.html'
      ,
        key: 'comments'
        title: 'datatable.label.comments'
        sort: 'comments'
      ,
        key: 'createdAt'
        title: 'datatable.label.created.at'
        sort: 'createdAt'
        type: 'dateTime'
        hideInList: true
      ]

    AssetManagerBase.valuePostProcessor = (path, value, row) ->
      if path == 'assetData'
        for data in value
          if data.type? and @dataTemplates[data.type]?
            data._template = @dataTemplates[data.type]

      return value

    AssetManagerBase.getDetailTemplateUrl = ->
      'mundo-admin/assets/views/asset-detail.tpl.html'

    AssetManagerBase.getDetailModalTemplateUrl = ->
      'mundo-admin/assets/views/asset-detail-modal.tpl.html'
    #   data
    AssetManagerBase.addData = (id, data) ->
      AssetDataManager.new(id, data)

    AssetManagerBase.removeData = (assetId, dataId) ->
      AssetDataManager.removeData(assetId, dataId)

    AssetManagerBase.getDataFieldsByType = (type) ->
      AssetDataManager.getExtraFormByType(type)

    addData =
      [
        icon: 'description'
        text: 'datatable.tooltips.addAssetData'
        permission: AssetManagerBase.editPermission
        action: (id) ->
          $mdDialog.show
            templateUrl: 'mundo-admin/assets/views/asset-data.modal.tpl.html'
            controller: 'AssetDataTypeCtrl'
            controllerAs: 'ctrl'
            skipHide: true
            locals:
              id: id
      ]

    AssetManagerBase.addExtraAction(addData)


    AssetManagerBase.getOne = AssetManagerBase.one

    AssetManagerBase.one = (id) ->
      AssetManagerBase.getOne(id)
      .then (result) ->
        result.units = []

        UnitsManager = new MundoDefaultManager()
        UnitsManager.setUrl('units')
        UnitsManager.getList(undefined, {
          'filter[0]': 'asset.id,' + id
          'filter[1]': 'unitDevices.deletedAt,NULL'
          'filter[3]': 'unitDevices.endDate,GTE ' + moment.utc().format()
        })
        .then (data) ->
          for unit in data
            result.units.push unit

        result

    AssetManagerBase
]
